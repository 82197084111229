import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Layout } from './utility/context/Layout';
import * as serviceWorker from './serviceWorker';
import { store } from './redux/storeConfig/store';
import Spinner from './components/@vuexy/spinner/Fallback-spinner';
import './index.scss';
import './assets/main.css';
import './@fake-db';
import { IntlProviderWrapper } from './utility/context/Internationalization';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  setUser,
  setIsAuthenticated,
  logout,
  setCreatedByAction
} from './redux/actions/auth/loginActions';
import "./assets/scss/style.css";

const LazyApp = lazy(() => import('./App'));

const jsx = (
  <Provider store={store}>
    
    <Suspense fallback={<Spinner />}>
      <Layout>
        <IntlProviderWrapper>
          <LazyApp />
          <ToastContainer />
        </IntlProviderWrapper>
      </Layout>
    </Suspense>
  </Provider>
);
let hasRendered = false;
const renderApp = (user = '') => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('root'));
    // registerServiceWorker();
    hasRendered = true;
  }
};

ReactDOM.render(<Spinner />, document.getElementById('root'));

const renderLogin = () => {
  store.dispatch(setIsAuthenticated(false));
  renderApp();
  // history.push('/');
};

try {
  let user = localStorage.getItem('user');

  if (user) {
    user = JSON.parse(user);
    store.dispatch(setUser(user));    
    store.dispatch(setCreatedByAction(user.createdBy))
    store.dispatch(setIsAuthenticated(true));
    renderApp();
    // history.push('/dashboard');
  } else {
    renderLogin();
  }
} catch (e) {
  renderLogin();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
