import React from 'react';
import { IntlProvider } from 'react-intl';

import messages_en from '../../assets/data/locales/en.json';
import messages_ar from '../../assets/data/locales/ar.json';

const menu_messages = {
  en: messages_en,
  ar: messages_ar,
};

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  lang = localStorage.getItem('dir') === 'rtl' ? 'ar' : 'en'
  state = {
    locale: localStorage.getItem('dir') === 'rtl' ? 'ar' : 'en',
    messages:
      menu_messages[localStorage.getItem('dir') === 'rtl' ? 'ar' : 'en'],
  };

  render() {
    
    const { children } = this.props;
    const { locale, messages } = this.state;
    
    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: (language) => {
            
            this.setState({
              locale: language,
              messages: menu_messages[language],
            });
          },
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale='en'
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };
