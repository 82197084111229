const initState = {
    data: []     
  };
  
  const notificaitonReducer = (state = initState, action) => {
    switch (action.type) {
      case 'NOTIFY_SET_DATA':
        return {
          ...state,
          data: action.payload.data,
        };
     
      default:
        return { ...state };
    }
  };
  
  export default notificaitonReducer;
  