const initState = {
  data: [],
  subData: [],
};

const categoryReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        data: action.payload.data,
      };
    case 'SET_SUB_DATA':
      return {
        ...state,
        subData: action.payload.data,
      };
    default:
      return { ...state };
  }
};

export default categoryReducer;
