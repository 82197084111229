const initState = {
    data: []    
  };
  
  const settingReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_SETTING':
        return {
          ...state,
          data: action.payload.data,
        };
      
      default:
        return { ...state };
    }
  };
  
  export default settingReducer;
  