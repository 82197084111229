import { combineReducers } from 'redux';
import customizer from './customizer/';
import auth from './auth/';
import navbar from './navbar/Index';
import LayoutReducer from './layoutReducer';
import categoryReducer from './categoryReducer';
import categoryLocationReducer from './categoryLocationReducer';
import cityReducer from './cityReducer';
import paginationReducer from './paginationReducer';
import StoreReduce from './store/storeReducer';
import StoreItemReduce from './store/storeItemReducer';
import notificaitonReducer from './notification/notificationReducer';
import CouponeReducer from './coupon/couponeReducer';
import SettingReducer from './setting/settingReducer';
import StoreOrderReducer from './storeOreder/storeOrderReducer';
import reportReducer from './report/reportReducer';
import storeReportReducer from './report/storeReportReducer';
import itemReportReducer from './report/itemReportReducer';

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  layout: LayoutReducer,
  category: categoryReducer,
  categoryLocations: categoryLocationReducer,
  city : cityReducer,
  pagination: paginationReducer,
  store: StoreReduce,
  storeItem: StoreItemReduce,
  notification: notificaitonReducer,
  coupon: CouponeReducer,
  setting: SettingReducer,
  storeOrder: StoreOrderReducer,
  UserOrderReport:reportReducer,
  StoreOrderReport:storeReportReducer,
  ItemOrderReport:itemReportReducer,
});

export default rootReducer;
