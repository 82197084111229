import React from 'react';
import * as Icon from 'react-feather';
function nvlink() {
  var user = localStorage.getItem('user')
    user = JSON.parse(user)
    var nv=(user && (user.type === 'STORE-ADMIN' || user.type === 'STORE'))?`/store/item?storeID=${user.store.id}`:"/store"

    return nv
}

const storeNavigationConfig = [
  {
    id: 'home',
    title: 'Dashboard',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['STA'],
    navLink: '/storeDashboard',
  },
//   {
//     id: 'home',
//     title: 'Dashboard',
//     type: 'item',
//     icon: <Icon.Home size={20} />,
//     // permissions: ['admin', 'editor'],
//     navLink: '/dashboard',
//   },
  // {
  //   id: 'store',
  //   title: 'Vendors',
  //   type: 'item',
  //   icon: <Icon.ShoppingBag size={20} />,
  //   // permissions: ['admin', 'editor'],
  //   navLink: '/store',
  // },

  {

    id: 'Vendor',
    title: 'Vendor',
    type: 'item',
    icon: <Icon.ShoppingBag size={20} />,
    // permissions: ["STA","STU"],
    navLink:nvlink(),
  },
  {
    id: 'VendorOrder',
    title: 'VendorOrder',
    type: 'item',
    icon: <Icon.ShoppingBag size={20} />,
    navLink: '/storeOrder'
  }

];

export default storeNavigationConfig;
