const initState = {
    data: []    
  };
  
  const itemReportReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_ITEM_ORDER_REPORT':
        return {
          ...state,
          data: action.payload.data,
        };
      default:
        return { ...state };
    }
  };
  
  export default itemReportReducer;
  
