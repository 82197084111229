import {history} from '../../../history';
import {store} from "../../storeConfig/store";
import {api} from "../../../services/api";

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}
export const setUser = data => {
  return {
    type: "SET_USER",
    payload: { data }
  };
};

export const setIsAuthenticated = data => {
  
  return {
    type: "SET_IS_AUTHENTICATED",
    payload: { data }
  };
};

export const setCreatedByAction = data => {
  return {
    type: "SET_CREATED_BY",
    payload: {data: data}
  }
}

export const logout = () => {
  setIsAuthenticated(false)
  setUser({});
  localStorage.removeItem("user");
  
  history.push('/')
  return {
    type: "LOGOUT"
  };
}


export const getLoginUser = () => {
  return async (dispatch,store) => {
    let user = localStorage.getItem('user');
   
    if (user) {
      user = JSON.parse(user);
      console.log("useruser : " +JSON.stringify(user.type))
     if(user.type != 'STORE-ADMIN' && user.type != 'STORE'){

     
      const response = await api(`subAdmin?id=${user.id}`,{},'get');
      
      if (response.status === 200) {
         
        
        if(response.data.data.users.length > 0){
          let updateuser = {...user,permission: response.data.data.users[0].permission}
          
          dispatch(setUser(updateuser));
          localStorage.setItem('user', JSON.stringify(updateuser))
        }
      }
    }
    }
  }
}