const initState = {
  user: null,
  isAuthenticated: false,
  createdBy: 1
};
export const login = (state = initState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload.data
      };
    case "SET_IS_AUTHENTICATED":
      return { ...state, isAuthenticated: action.payload.data };
    case "SET_CREATED_BY":
      
      return { ...state, createdBy: action.payload.data };
    case "LOGOUT":
      return { ...state, ...initState };
    default:
      return { ...state };
  }
}
