const initState = {
    newRequestCount: 0,
    acceptCount: 0,
    readyToDeliveryCount: 0,
    onTheWayCount: 0,
    deliverdCount: 0,
    rejectCount: 0
};
  
const storeReducer = (state = initState, action) => {
    switch (action.type) {
        case 'NEWREQUEST_ORDER_COUNT':
            return {
              ...state,
              newRequestCount: action.payload.data,
            };
        case 'ACCEPT_ORDER_COUNT':
            return {
                ...state,
                acceptCount: action.payload.data,
            };
        case 'READYTODELIVERY_ORDER_COUNT':
            return {
                ...state,
                readyToDeliveryCount: action.payload.data,
            };
        case 'ONTHEWAY_ORDER_COUNT':
            return {
                ...state,
                onTheWayCount: action.payload.data,
            };
        case 'DELIVERY_ORDER_COUNT':
            return {
                ...state,
                deliverdCount: action.payload.data
            }
        case 'REJECT_ORDER_COUNT':
            return {
                ...state,
                rejectCount: action.payload.data
            }
        default:
            return { ...state };
    }
};
  
  export default storeReducer;
  