import React from 'react';
import * as Icon from 'react-feather';
const navigationConfig = [
  {
    id: 'home',
    title: 'Dashboard',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: ['A', 'SUB'],
    navLink: '/dashboard',
  },
  // {
  //   id: 'stats',
  //   title: 'Stats',
  //   type: 'collapse',
  //   icon: <Icon.BarChart size={20} />,
  //   children: [
  //     {
  //       id: 'stats_orders',
  //       title: 'Orders',
  //       type: 'item',
  //       icon: <Icon.ShoppingBag size={12} />,
  //       // permissions: ["admin", "editor"],
  //       navLink: '/stats/orders',
  //     },
  //     {
  //       id: 'stats_customers',
  //       title: 'Customers',
  //       type: 'item',
  //       icon: <Icon.Users size={12} />,
  //       // permissions: ["admin", "editor"],
  //       navLink: '/stats/customers',
  //     },
  //     {
  //       id: 'stats_drivers',
  //       title: 'Drivers',
  //       type: 'item',
  //       icon: <Icon.UserPlus size={12} />,
  //       // permissions: ["admin", "editor"],
  //       navLink: '/stats/drivers',
  //     },
  //   ],
  // },
  {
    id: 'customers',
    title: 'Customers',
    type: 'collapse',
    permissions: ['A', 'SUB'],
    icon: <Icon.Users size={20} />,
    children: [
      {
        id: 'customer_admins',
        title: 'Admins',
        type: 'item',
        icon: <Icon.Settings size={12} />,
        permissions: ['A', 'SUB'],
        navLink: '/customer/admins',
      },
      {
        id: 'customer_customers',
        title: 'Customers',
        type: 'item',
        icon: <Icon.Users size={12} />,
        permissions: ['A', 'SUB'],
        navLink: '/customer/customers',
      },
      {
        id: 'customer_driver',
        title: 'salesmen',
        type: 'item',
        icon: <Icon.Users size={12} />,
        permissions: ['A', 'SUB'],
        navLink: '/driver',
      },


      // {
      //   id: 'customer_drivers',
      //   title: 'Drivers',
      //   type: 'item',
      //   icon: <Icon.UserPlus size={12} />,
      //   permissions: ['A', 'SUB'],
      //   navLink: '/customer/drivers',
      // },
      // {
      //   id: 'customer_blocked_drivers',
      //   title: 'Blocked Drivers',
      //   type: 'item',
      //   icon: <Icon.UserMinus size={12} />,
      //   permissions: ['A', 'SUB'],
      //   navLink: '/customer/drivers/blocked',
      // },
      // {
      //   id: 'customer_online_drivers',
      //   title: 'Online Drivers',
      //   type: 'item',
      //   icon: <Icon.Truck size={12} />,
      //   permissions: ['A', 'SUB'],
      //   navLink: '/customer/drivers/online',
      // },
      // {
      //   id: 'customer_store',
      //   title: 'Store',
      //   type: 'item',
      //   icon: <Icon.ShoppingBag size={12} />,
      //   permissions: ['A', 'SUB'],
      //   navLink: '/customer/store',
      // },
    ],
  },
  // {
  //   id: 'category',
  //   title: 'Category',
  //   type: 'collapse',
  //   icon: <Icon.Grid size={20} />,
  //   children: [
  //     {
  //       id: 'category_categories',
  //       title: 'Category',
  //       type: 'item',
  //       icon: <Icon.Grid size={12} />,
  //       permissions: ['A', 'SUB'],
  //       navLink: '/category',
  //     },
  //     // {
  //     //   id: 'category_store',
  //     //   title: 'Store',
  //     //   type: 'item',
  //     //   icon: <Icon.ShoppingBag size={12} />,
  //     //   permissions: ['A', 'SUB'],
  //     //   navLink: '/category/store',
  //     // }
  //   ],
  // },
  {
    id: 'category',
    title: 'Category',
    type: 'item',
    icon: <Icon.Grid size={20} />,
    permissions: ['A', 'SUB'],
    navLink: '/category',
  },
  // {
  //   id: 'superCategory',
  //   title: 'Super Category',
  //   type: 'item',
  //   icon: <Icon.Grid size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/supercategory',
  // },
  {
    id: 'Vendors',
    title: 'Vendors',
    type: 'item',
    icon: <Icon.ShoppingBag size={20} />,
    permissions: ['A', 'SUB', 'STA'],
    navLink: '/store',
  },
  // {
  //   id: 'Ecommerce',
  //   title: 'Ecommerce',
  //   type: 'item',
  //   icon: <Icon.ShoppingBag size={20} />,
  //   permissions: ['A', 'SUB', 'STA'],
  //   navLink: '/Ecommerce',
  // },
  {
    id: 'VendorOrder',
    title: 'VendorOrder',
    type: 'item',
    icon: <Icon.Truck size={20} />,
    permissions: ['A', 'SUB',"STA","STU"],
    navLink: '/storeOrder'
  },
  {
    id: 'CourierOrder',
    title: 'Courier Order',
    type: 'item',
    icon: <Icon.Truck size={20} />,
    permissions: ['A', 'SUB',"STA","STU"],
    navLink: '/courierOrder'
  },
  {
    id: 'VendorEarnings',
    title: 'VendorEarnings',
    type: 'item',
    icon: <Icon.DollarSign size={20} />,
    permissions: ['A', 'SUB',"STA","STU"],
    navLink: '/earnings'
  },
  // {
  //   id: 'DriverEarnings',
  //   title: 'DriverEarnings',
  //   permissions: ['A', 'SUB'],
  //   type: 'item',
  //   icon: <Icon.DollarSign size={20} />,
  //   navLink: '/driverEarning'
  // },

  {
    id: 'promotions',
    title: 'Promotions',
    type: 'item',
    icon: <Icon.Gift size={20} />,
    permissions: ['A', 'SUB'],
    navLink: '/promotions',
  },
  {
    id: 'Coupon',
    title: 'Coupon',
    type: 'item',
    icon: <Icon.Gift size={20} />,
    permissions: ['A', 'SUB'],
    navLink: '/coupon'
  },
  // {
  //   id: 'city',
  //   title: 'city',
  //   type: 'item',
  //   icon: <Icon.Map size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/city'
  // },
  {
    id: 'reports',
    title: 'Reports',
    type: 'collapse',
    permissions: ['A', 'SUB'],
    icon: <Icon.BarChart size={20} />,
    children: [
      {
        id: 'user_order_report',
        title: 'User Amount Report',
        type: 'item',
        icon: <Icon.FilePlus size={12} />,
        permissions: ['A', 'SUB'],
        navLink: '/userOrderReport',
      },
      {
        id: 'order_detail_report',
        title: 'Order Report',
        type: 'item',
        icon: <Icon.FilePlus size={12} />,
        permissions: ['A', 'SUB'],
        navLink: '/orderDetailReport',
      },
      {
        id: 'store_order_report',
        title: 'Store Order Report',
        type: 'item',
        icon: <Icon.FilePlus size={12} />,
        permissions: ['A', 'SUB'],
        navLink: '/storeOrderReport',
      },
      {
        id: 'item_order_report',
        title: 'Item Order Report',
        type: 'item',
        icon: <Icon.FilePlus size={12} />,
        permissions: ['A', 'SUB'],
        navLink: '/itemOrderReport',
      },
      {
        id: 'driver_reports',
        title: 'Driver Reports',
        type: 'item',
        icon: <Icon.FilePlus size={12} />,
        permissions: ['A', 'SUB'],
        navLink: '/driverIssues',
      },
      {
        id: 'store_reports',
        title: 'Store Reports',
        type: 'item',
        icon: <Icon.FilePlus size={12} />,
        permissions: ['A', 'SUB'],
        navLink: '/storeIssues',
      }
    ],
  },
  {
    id: 'notification',
    title: 'Notification',
    type: 'item',
    icon: <Icon.Bell size={20} />,
    permissions: ['A', 'SUB'],
    navLink: '/notification',
  },
  {
    id: 'sendNotification',
    title: 'Send Notification',
    type: 'item',
    icon: <Icon.Bell size={20} />,
    permissions: ['A', 'SUB'],
    navLink: '/sendNotification',
  },
  {
    id: 'Setting',
    title: 'Setting',
    type: 'item',
    icon: <Icon.Settings size={20} />,
    permissions: ['A', 'SUB'],
    navLink: '/setting'
  }
  // {
  //   id: 'datetime',
  //   title: 'Date & Time',
  //   type: 'collapse',
  //   icon: <Icon.Calendar size={20} />,
  //   children: [
  //     {
  //       id: 'datetime_year',
  //       title: 'Year',
  //       type: 'item',
  //       icon: <Icon.Calendar size={12} />,
  //       permissions: ['A', 'SUB'],
  //       navLink: '/datetime/year',
  //     },
  //     {
  //       id: 'datetime_time',
  //       title: 'Time',
  //       type: 'item',
  //       icon: <Icon.Clock size={12} />,
  //       permissions: ['A', 'SUB'],
  //       navLink: '/datetime/store',
  //     }
  //   ],
  // },
  // {
  //   id: 'orders',
  //   title: 'Orders',
  //   type: 'item',
  //   icon: <Icon.ShoppingBag size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/orders',
  // },
  // {
  //   id: 'del_charges',
  //   title: 'Delevery Charge',
  //   type: 'item',
  //   icon: <Icon.DivideCircle size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/deleverycharge',
  // },
  // {
  //   id: 'store_order',
  //   title: 'Store Order',
  //   type: 'item',
  //   icon: <Icon.FilePlus size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/store_order',
  // },
  // {
  //   id: 'banks',
  //   title: 'Banks',
  //   type: 'item',
  //   icon: <Icon.DollarSign size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/banks',
  // },
  // {
  //   id: 'notification',
  //   title: 'Notification',
  //   type: 'item',
  //   icon: <Icon.Bell size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/notification',
  // },
  // {
  //   id: 'con_store',
  //   title: 'Contracting stores',
  //   type: 'item',
  //   icon: <Icon.Archive size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/con_store'
  // },
  // {
  //   id: 'tnc_about',
  //   title: 'Terms And Conditions',
  //   type: 'collapse',
  //   icon: <Icon.FileText size={20} />,
  //   permissions: ['A', 'SUB'],
  //   children: [
  //     {
  //       id: 'terms',
  //       title: 'Terms And Conditions',
  //       type: 'item',
  //       icon: <Icon.FileText size={12} />,
  //       permissions: ['A', 'SUB'],
  //       navLink: '/terms',
  //     },
  //     {
  //       id: 'about',
  //       title: 'About',
  //       type: 'item',
  //       icon: <Icon.File size={12} />,
  //       permissions: ['A', 'SUB'],
  //       navLink: '/about',
  //     }
  //   ],
  // },
  // {
  //   id: 'messages',
  //   title: 'Messages',
  //   type: 'item',
  //   icon: <Icon.MessageCircle size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/messages'
  // },
  // {
  //   id: 'cars',
  //   title: 'Kind of cars',
  //   type: 'item',
  //   icon: <Icon.Truck size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/cars'
  // },
  // {
  //   id: 'coupons',
  //   title: 'Coupons',
  //   type: 'item',
  //   icon: <Icon.CreditCard size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/coupons'
  // },
  // {
  //   id: 'couponeUser',
  //   title: 'Apply Coupon',
  //   type: 'item',
  //   icon: <Icon.Copy size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/couponeUser'
  // },
  // {
  //   id: 'systemDeleteOrder',
  //   title: 'Deleted By System',
  //   type: 'item',
  //   icon: <Icon.Trash size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/systemDeleteOrder'
  // },
  // {
  //   id: 'reports',
  //   title: 'Deleted By System',
  //   type: 'item',
  //   icon: <Icon.List size={20} />,
  //   permissions: ['A', 'SUB'],
  //   navLink: '/reports'
  // },
  // {
  //   id: 'complements',
  //   title: 'Complements',
  //   type: 'item',
  //   icon: <Icon.AlertTriangle size={20} />,
  //   // permissions: ["admin", "editor"],
  //   navLink: '/complements'
  // },
  // {
  //   id: 'sendNotification',
  //   title: 'Send Notification',
  //   type: 'item',
  //   icon: <Icon.Send size={20} />,
  //   // permissions: ["admin", "editor"],
  //   navLink: '/sendNotification'
  // },
  // {
  //   id: 'version',
  //   title: 'Version',
  //   type: 'item',
  //   icon: <Icon.GitBranch size={20} />,
  //   // permissions: ["admin", "editor"],
  //   navLink: '/version'
  // },
  // {
  //   id: 'user',
  //   title: 'User',
  //   type: 'item',
  //   icon: <Icon.Home size={20} />,
  //   // permissions: ['admin', 'editor'],
  //   navLink: '/users',
  // },
];

export default navigationConfig;
