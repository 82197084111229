const initState = {
    data: [],
    dataIndex: []
  };
  
  const storeItemReducer = (state = initState, action) => {
    switch (action.type) {
      case 'STORE_ITEM_SET_DATA':
        return {
          ...state,
          data: action.payload.data,
        };
      case 'STORE_INDEX_ITEM_SET_DATA':
          return {
            ...state,
            dataIndex: action.payload.data,
          };
     
      default:
        return { ...state };
    }
  };
  
  export default storeItemReducer;
  