const initState = {
  data: [],
  allCity: [],
  isAllCityLoaded: false,
};

const cityReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        data: action.payload.data,
      };
    case "SET_CITY_DROPDOWN":
      return {
        ...state,
        allCity: action.payload.data,
        isAllCityLoaded: true,
      };
    default:
      return { ...state };
  }
};

export default cityReducer;
