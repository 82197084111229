const initState = {
    data: []    
  };
  
  const reportReducer = (state = initState, action) => {
    console.log(action);
    switch (action.type) {
      case 'SET_USER_ORDER_REPORT':
        return {
          ...state,
          data: action.payload.data,
        };
      case 'SET_USER_REPORT_REPORT':
        return {
          ...state,
          data: action.payload.data,
        };
      default:
        return { ...state };
    }
  };
  
  export default reportReducer;
  
