import React from 'react';
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from 'reactstrap';
import { IntlContext } from '../../../utility/context/Internationalization';
import ReactCountryFlag from 'react-country-flag';
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';
import * as Icon from 'react-feather';
import classnames from 'classnames';
import Autocomplete from '../../../components/@vuexy/autoComplete/AutoCompleteComponent';
import { history } from '../../../history';
import { ContextLayout } from '../../../utility/context/Layout';
import {api} from '../../../services/api';
import { FormattedMessage, FormattedNumberParts } from "react-intl"
import { connect } from 'react-redux';
import {logout} from '../../../redux/actions/auth/loginActions';
import { useIntl } from 'react-intl';

const UserDropdown = (props) => {
  const intl = useIntl();
  const t = (id) => intl.formatMessage({ id });

  return (
    <DropdownMenu right>
      <DropdownItem onClick={() => history.push('/profile')}>
        <Icon.User size={14} className='mr-50' />
        <span className='align-middle'>{t('Edit Profile')}</span>
      </DropdownItem>

      <DropdownItem divider />
      <DropdownItem onClick={() => history.push('/changePassword')}>
        <Icon.User size={14} className='mr-50' />
        <span className='align-middle'>{t('changePassword')}</span>
      </DropdownItem>

      <DropdownItem divider />
      
      <DropdownItem       
        onClick={(e) => {props.logout()}}
      >
        <Icon.Power size={14} className='mr-50' />
        <span className='align-middle'>{t('logout')}</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    suggestions: [],
    count: {
      unread: 0
    },
    locale: localStorage.getItem('dir') === 'rtl'?'ar':'en'
    
  };

  componentDidMount() {  
   
    if(this.props.userRole === 'A') {
      // this.getCount();
      this.myInterval =  setInterval(() => {  
        
        if(this.props.isAuthenticated && this.props.userRole === 'A'){
          // this.getCount();
        }    
      }, 5000);
    }
    
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };
  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown });

  getCount = async () => {
    let getNotificationCount = await api('notif/unreadCount',{},'get',false);
    
    if(getNotificationCount && getNotificationCount.status === 200){      
      this.setState({count: getNotificationCount.data})
    }      
  };
  
  componentWillUnmount(){
    
      clearInterval( this.myInterval )
  }
  render() {
    return (
      <ul className='nav navbar-nav navbar-nav-user float-right'>
        {/* <IntlContext.Consumer>
          {(context) => {
            let langArr = {
              en: 'English',
              ar: 'Arabic',
            };
            return (
              <Dropdown
                tag='li'
                className='dropdown-language nav-item'
                isOpen={this.state.langDropdown}
                toggle={this.handleLangDropdown}
                data-tour='language'
              >
                <DropdownToggle tag='a' className='nav-link'>
                  <ReactCountryFlag
                    className='country-flag'
                    countryCode={
                      context.state.locale === 'en'
                        ? 'us'
                        : 'sa'
                    }
                    svg
                  />
                  <span className='d-sm-inline-block d-none text-capitalize align-middle ml-50'>
                  <FormattedMessage id={langArr[context.state.locale]}/>
                  </span>
                </DropdownToggle>
                <ContextLayout.Consumer>
                  {(con) => {
                    return (
                      <DropdownMenu right>
                        <DropdownItem
                          tag='a'
                          onClick={(e) => {
                            con.switchDir('ltr')
                            context.switchLanguage('en')}}
                        >
                          <ReactCountryFlag
                            className='country-flag'
                            countryCode='us'
                            svg
                          />
                          <span className='ml-1'>English</span>
                        </DropdownItem>
                        <DropdownItem
                          tag='a'
                          onClick={(e) => {
                            con.switchDir('rtl')
                            context.switchLanguage('ar');
                          }}
                        > 
                         
                          
                           <ReactCountryFlag
                            className='country-flag'
                            countryCode='sa'
                            svg
                          /> 
                          
                          <span className='ml-1'><FormattedMessage id='Arabic'></FormattedMessage></span>
                        </DropdownItem>
                      </DropdownMenu>
                    );
                  }}
                </ContextLayout.Consumer>
              </Dropdown>
            );
          }}
        </IntlContext.Consumer> */}

     
        {(this.props.userRole=="STA") ? (
       <span></span>
      ) : (
        <UncontrolledDropdown
          tag='li'
          className='dropdown-notification nav-item'
        >
          <DropdownToggle tag='a' className='nav-link nav-link-label' onClick={()=> history.push("/notification")}>
            <Icon.Bell size={21} />
            <Badge pill color='primary' className='badge-up'>
            
              {' '}
              {this.state.count.unread}
            </Badge>
          </DropdownToggle>

          {/* <DropdownMenu tag='ul' right className='dropdown-menu-media'>
           
            <PerfectScrollbar
              className='media-list overflow-hidden position-relative'
              options={{
                wheelPropagation: false,
              }}
            >
              <div className='d-flex justify-content-between'>
                <Media className='d-flex align-items-start'>
                  <Media left href='#'>
                    <Icon.PlusSquare
                      className='font-medium-5 primary'
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className='primary media-heading' tag='h6'>
                      You have new order!
                    </Media>
                    <p className='notification-text'>
                      Are your going to meet me tonight?
                    </p>
                  </Media>
                  <small>
                    <time
                      className='media-meta'
                      dateTime='2015-06-11T18:29:20+08:00'
                    >
                      9 hours ago
                    </time>
                  </small>
                </Media>
              </div>
            </PerfectScrollbar>
            
          </DropdownMenu> */}
        </UncontrolledDropdown>
      )}
        

        <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
          <DropdownToggle tag='a' className='nav-link dropdown-user-link'>
            <div className='user-nav d-sm-flex d-none'>
              <span className='user-name text-bold-600'>
                {this.props.userRole=="STA"?(this.state.locale=='ar'?this.props.storearName:this.props.storeName):this.props.userName}
              </span>
              <span className='user-status'>{this.props.userRole=="STA"? '':"Available"}</span>
            </div>
            <span data-tour='user'>
              <img
                src={this.props.userRole=="STA"?this.props.storeImg:this.props.userImg}
                className='round'
                height='40'
                width='40'
                alt=''
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}

const mapStateToProps = state => {
  
  return {
    userRole: state.auth.login.user.role ,
    isAuthenticated: state.auth.login.isAuthenticated  
  }
}
export default connect(mapStateToProps,{logout})(NavbarUser);
