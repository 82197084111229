// import external modules
import axios from "axios";
// import { toast } from 'react-redux-toast';
import { toast } from "react-toastify";
import { setIsLoading } from "../redux/actions/layoutAction";

// import internal(own) modules
import { store } from "../redux/storeConfig/store";
import { logout } from "../redux/actions/auth/loginActions";
import { history } from "../history";
// import { setIsLoading } from '../redux/actions/layoutAction';

let mainUrl = 'https://api.purposedeliverservice.com/api/v1';
// let mainUrl = "https://localhost:8081/api/v1";
//
export const api = async (endpoint, data, type, isLoading = true) => {
  let response;
  const { user } = store.getState().auth.login;
  let token = "";
  if (user) token = user.token;
  if (isLoading) {
    store.dispatch(setIsLoading(true));
  }

  let headers = { "Content-Type": "application/json" };
  try {
    switch (type) {
      case "post":
        headers["Authorization"] = `Bearer ${token}`;
        response = await axios.post(`${mainUrl}/${endpoint}`, data, {
          headers,
        });
        break;
      case "postWithoutToken":
        response = await axios
          .post(`${mainUrl}/${endpoint}`, data, { headers })
          .catch((error) => {
            if (error && error.response) {
              if (error.response.status === 400) {
                toast.error("Invalid credentials");
              }
              response = error.response;
            }
          });
        break;
      case "postMultipart":
        headers["Content-Type"] = "multipart/form-data";
        headers["Authorization"] = `Bearer ${token}`;
        response = await axios.post(`${mainUrl}/${endpoint}`, data, {
          headers,
        });
        // .catch(error => {
        //   if (error.response.status === 400) {
        //     toast.error("User credentials", error.response.data.message);
        //   }
        //   response = error.response;
        // });
        break;
      case "get":
        headers["Authorization"] = `Bearer ${token}`;
        response = await axios.get(`${mainUrl}/${endpoint}`, { headers });
        break;
      case "put":
        headers["Authorization"] = `Bearer ${token}`;
        headers["Content-Type"] = "multipart/form-data";
        response = await axios.put(`${mainUrl}/${endpoint}`, data, { headers });
        break;
      case "putWithoutForm":
        headers["Authorization"] = `Bearer ${token}`;
        // headers['Content-Type'] = 'multipart/form-data';
        response = await axios.put(`${mainUrl}/${endpoint}`, data, { headers });
        break;
      case "patch":
        headers["Authorization"] = `Bearer ${token}`;
        headers["Content-Type"] = "multipart/form-data";
        response = await axios.patch(`${mainUrl}/${endpoint}`, data, {
          headers,
        });
        break;
      case "delete":
        headers["Authorization"] = `Bearer ${token}`;
        response = await axios.delete(`${mainUrl}/${endpoint}`, {
          data,
          headers,
        });
        break;
      default:
        return true;
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
        // toast.error(error.respo);
      }
      if (
        error.response.status === 401 ||
        error.response.status === 403 ||
        error.response.status === 503
      ) {
        store.dispatch(logout());
        history.push("/login");
      }
      response = error.response;
    }
  }
  store.dispatch(setIsLoading(false));
  return response;
};
