const initState = {
    data: [],
    storeCategory: []   
  };
  
  const storeReducer = (state = initState, action) => {
    switch (action.type) {
      case 'STORE_SET_DATA':
        return {
          ...state,
          data: action.payload.data,
        };
      case 'STORE_CATEGORY_SET_DATA':
        return {
          ...state,
          storeCategory: action.payload.data
        }
      default:
        return { ...state };
    }
  };
  
  export default storeReducer;
  