const initState = {
  page: 1,
  pages: 1,
  limit: 10,
};

const paginationReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload.data,
      };
    case 'SET_PAGES':
      return {
        ...state,
        pages: action.payload.data,
      };
    case 'SET_LIMIT':
      return {
        ...state,
        limit: action.payload.data,
      };
    default:
      return { ...state };
  }
};

export default paginationReducer;
