const initState = {
    data: []    
  };
  
  const couponeReducer = (state = initState, action) => {
    switch (action.type) {
      case 'COUPONE_SET_DATA':
console.log('-=-=-=');
        return {
          ...state,
          data: action.payload.data,
        };
     
      default:
        return { ...state };
    }
  };
  
  export default couponeReducer;
  
